export default {
  install: function (Vue) {
    Vue.mixin({
      methods: {
        $notifySuccess (text) {
          this.$bvToast.toast(text, {
            title: 'Aktion erfolgreich',
            autoHideDelay: 5000,
            variant: 'success',
            solid: true,
            appendToast: true
          })
        },
        $notifyInfo (text) {
          this.$bvToast.toast(text, {
            autoHideDelay: 5000,
            variant: 'primary',
            solid: true,
            appendToast: true,
            noCloseButton: true,
          })
        },
        $notifyWarn (text) {
          this.$bvToast.toast(text, {
            autoHideDelay: 5000,
            variant: 'warning',
            solid: true,
            appendToast: true,
            noCloseButton: true,
          })
        },
        $notifyError (e) {
          console.error(e)
          if (e.response) {
            this.$bvToast.toast(`Status ${e.response.status} - Fehler: ${e.response.data}`, {
              title: 'Aktion fehlerhaft',
              autoHideDelay: 5000,
              variant: 'danger',
              solid: true,
              appendToast: true
            })
          } else {
            this.$bvToast.toast(e, {
              title: 'Aktion fehlerhaft',
              autoHideDelay: 5000,
              variant: 'danger',
              solid: true,
              appendToast: true
            });
          }
        }
      },
    })
  }
}
